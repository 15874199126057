<template>
    <form-layout>
        <div class="relative px-4 py-4 sm:px-6 sm:py-4 lg:py-8 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <p class="mt-2 text-center text-medium text-white">
                    <img
                        class="mx-auto h-24 w-auto"
                        src="@/assets/logo.png"
                        alt="Workflow"
                    />
                    &nbsp;Member Signup
                </p>
            </div>
            <div class="mt-1 sm:mx-auto sm:w-full sm:max-w-md">
                <div class="bg-zinc-900 py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div class="space-y-2">
                        <!-- Birth Day -->
                        <div>
                            <label for="email" class="block text-sm font-medium text-white"
                                >Username</label
                            >
                            <div class="mt-1">
                                <input
                                    type="text"
                                    v-model="formData.username"
                                    name="username"
                                    id="username"
                                    class="
                                        shadow-sm
                                        focus:ring-indigo-500 focus:border-indigo-500
                                        block
                                        w-full
                                        sm:text-sm
                                        border-gray-300
                                        rounded-md
                                    "
                                    placeholder="Username"
                                />
                                <span class="font-small text-pink-800">{{ errors['username'] }}</span>
                            </div>
                        </div>

                        <!-- Birth Month -->
                        <div>
                            <label for="password" class="block text-sm font-medium text-white"
                                >Password</label
                            >
                            <div class="mt-1">
                                <input
                                    type="password"
                                    v-model="formData.password"
                                    name="password"
                                    id="password"
                                    class="
                                        shadow-sm
                                        focus:ring-indigo-500 focus:border-indigo-500
                                        block
                                        w-full
                                        sm:text-sm
                                        border-gray-300
                                        rounded-md
                                    "
                                    placeholder="Must be a minimum of six characters"
                                />
                                <span class="font-small text-pink-800">{{ errors['password'] }}</span>
                            </div>
                        </div>

                        <!-- Birth Month -->
                        <div>
                            <label for="password" class="block text-sm font-medium text-white"
                                >Confirm Password</label
                            >
                            <div class="mt-1">
                                <input
                                    type="password"
                                    v-model="formData.c_password"
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    class="
                                        shadow-sm
                                        focus:ring-indigo-500 focus:border-indigo-500
                                        block
                                        w-full
                                        sm:text-sm
                                        border-gray-300
                                        rounded-md
                                    "
                                    placeholder="Must match your password"
                                />
                                <span class="font-small text-pink-800">{{ errors['c_password'] }}</span>
                            </div>
                        </div>

                        <div class="mt-2 text-sm font-medium text-white text-white">
                            Choose your membership type:
                        </div>

                        <div class="flex flex-justify-between">
                            <div class="p-3 shadow-sm  sm:text-sm  rounded-md
                                        hover:bg-pink-400
                                        text-sm font-medium text-white
                                        rounded-md border-2 border-transparent"
                                 :class="{ 'rounded-md border-2 border-pink-700' : formData.type == 'full'}"
                                 @click="formData.type ='full'">
                                Full Membership<br>
                                <span class="font-medium text-gray-700">
                                    Full access to member videos, store and purchases.
                                </span>
                            </div>

                            <div class="ml-1 p-3 shadow-sm  sm:text-sm  rounded-md
                                        hover:bg-pink-400
                                        text-sm font-medium text-white
                                        rounded-md border-2 border-transparent"
                                 :class="{ 'rounded-md border-2 border-pink-700' : formData.type == 'free'}"
                                 @click="formData.type ='free'">
                                Store only<br>
                                <span class="font-medium text-gray-700">
                                    Access to store and purchases only.
                                </span>
                            </div>
                        </div>



                        <!-- Message -->
                        <div class="flex">
                            <div class="flex ">
                                <div class="text-sm basis-full">
                                    <div class="justify-items-center">
                                        <span class="font-small text-pink-700">
                                            All payments are processed by ccbill.com
                                        </span>
                                        <img
                                            src="@/assets/visa.png"
                                            alt="Visa cards"
                                            class="h-10 justify-center"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Submit Button -->
                        <div>
                            <button
                                type="submit"
                                @click="signUpToAccount"
                                class="
                                    w-full
                                    flex
                                    justify-center
                                    mt-2
                                    py-2
                                    px-4
                                    border border-transparent
                                    rounded-md
                                    shadow-sm
                                    text-sm
                                    font-medium
                                    text-white
                                    bg-pink-700
                                    hover:bg-pink-900
                                    focus:outline-none
                                    focus:ring-2
                                    focus:ring-offset-2
                                    focus:ring-indigo-500
                                    "
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form-layout>
</template>
  
  <script>
import { useStore }    from "vuex";
import alertError      from "@/alerts/error.vue";
import axios           from "axios";
import { saveStorage } from "@/saveStorage";
import formLayout      from "@/layouts/formLayout";
import { inject } from 'vue';
import { uuid } from 'vue3-uuid';


export default {
    components: {
        alertError,
        formLayout,
    },

    setup() {
      const namespace = inject('uuidNamespace');
      return { namespace };
    },

    data() {
        return {
            api_url: process.env.VUE_APP_API_URL,

            formData: {
                type:     "full",
                username: "",
                password: "",
                c_password: "",
            },
            store:  useStore(),
            errors: [],
        };
    },

    created() {
        // Set default account type
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('type')) {
            if (urlParams.get('type') == 'free') {
                this.formData.type = 'free'
            }
        }
    },

    methods: {
        signUpToAccount() {
          //create a hash of the username to use as a privacy compliant user ID
          const usernameHash = uuid.v5(this.formData.username, this.namespace);
          saveStorage('userId', usernameHash);

          window.dataLayer.push({
            'userId': usernameHash
          });

          if(this.formData.password === this.formData.c_password){
                axios
                .post(this.api_url + '/api/register', this.formData)
                .then((response) => {
                    if (this.formData.type === 'full') {
                        saveStorage('username', response.data.name)
                        //redirect to external payment page due to iframes not working
                        window.dataLayer.push({
                          'event': 'sign_up',
                          'userType': 'full',
                          'funnelType': 'subscription'
                        })
                        window.location.href = process.env.VUE_APP_CCBILL_URL + '?username=' + response.data.name
                    }
                    else {
                        this.error = false
                        window.dataLayer.push({
                          'event': 'sign_up',
                          'userType': 'free',
                          'funnelType': 'ecommerce'
                        })
                        saveStorage('auth', response.data)
                        saveStorage('token', response.data.token)
                        saveStorage('LoggedUser', true)
                        saveStorage('username', response.data.name)
                        saveStorage('isAdmin', response.data.is_admin)
                        this.$router.push({name: 'homePage'})
                    }
                })
                .catch((error) => {
                    var error_msg     = '';
                    var error_details = '';
                    if (error.response.data.message) {
                        error_msg = error.response.data.message;	
                    }
                    
                    if (error.response.data.errors) {
                        Object.keys(error.response.data.errors).forEach(key => {
                            error_details = error_details + error.response.data.errors[key].toString() + "<br>";

                            // load api errors into client errors
                            this.errors[key] = error.response.data.errors[key].toString();
                        });
                    }

                    this.$swal({
                        title:              error_msg,
                        html:               error_details,
                        confirmButtonColor: '#be185d',
                        confirmButtonText:  'Close',
                        icon:               'error',
                        background:         '#3f3f46',
                    })
                });
            }
            else {
                this.$swal({
                    title: 'Registration Error.',
                    text: 'The passwords do not match',
                    confirmButtonColor: '#be185d',
                    confirmButtonText: 'Close',
                    icon: 'error',
                    background: '#3f3f46',
                })
            }
        }
    },
};
</script>
  
  <style>
</style>