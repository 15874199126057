<template>
  <GTMScript />
  <router-view/>
</template>

<script>
import { initialiseGTM } from './composables/initialiseGTM'
import {onMounted} from "vue";

export default {
    name: 'App',
    components: {
    },
    setup() {
      onMounted(() => {
        initialiseGTM();
      });
    },
    created() {
        if (process.env.VUE_APP_ENV == 'production') {
            document.addEventListener('contextmenu', event => event.preventDefault());
        }
    }
}
</script>

<style>
</style>
